import "../styles/ShowSummary.css";
import {ReactComponent as ExtLink} from "../assets/external-link.svg";
import React, {useEffect, useState} from "react";
import {Star} from "./Star";
import {jsonInit, prettyNumber, prettyYears} from "../Utils";

export function ShowSummary({showId, highlights}) {
    const [title, setTitle] = useState("Loading...");
    const [years, setYears] = useState("");
    const [poster, setPoster] = useState("");
    const [rating, setRating] = useState("");
    const [ratingVotes, setRatingVotes] = useState("");
    const [hrs, setHrs] = useState("");
    const [hrsVotes, setHrsVotes] = useState("");
    const [highlighted, setHighlighted] = useState("");

    useEffect(() => {
        fetch(`https://hrs.avecyclop.com/data/shows/${showId}.json`, jsonInit)
            .then(response => response.json())
            .then(json => {
                setTitle(json.title.title);
                setYears(prettyYears(json.title))
                setPoster(json.title.posterUrl);
                setRating(json.title.rating.toFixed(1));
                setRatingVotes(prettyNumber(json.title.votes));
                setHrs(json.reviews.hrs.toFixed(1));
                setHrsVotes(prettyNumber(json.reviews.votes));
                if (highlights) {
                    setHighlighted(json.reviews.highlighted.map((item) => (
                        <p><a href={item.link} target="_blank" rel="noreferrer">{item.title} <ExtLink/></a></p>
                    )));
                    document.getElementById("highlights").style.display = "block";
                } else {
                    setHighlighted(null)
                    document.getElementById("highlights").style.display = "none";
                }
                document.getElementById("show").style.display = "grid";
            }, (error) => {
                console.error(error.message);
                document.getElementById("show").style.display = "none";
            })
    }, [showId, highlights]);

    return (
        <article id="show" className="show">
            <div className="poster">
                <img src={poster} alt={title + " poster"}/>
            </div>
            <div className="title">
                <h3>{title + " " + years}</h3>
            </div>
            <div className="rating">
                <Star type="rating"/><br/>
                {rating}<br/>
                ({ratingVotes})
            </div>
            <div className="hrs">
                <Star type="hrs"/><br/>
                {hrs}<br/>
                ({hrsVotes})
            </div>
            <div id="highlights" className="reviews">
                {highlighted}
            </div>
        </article>
    );
}
