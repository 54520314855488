import {useParams} from "react-router-dom";
import {ShowSummary} from "../components/ShowSummary";

export function Show() {
    let { showId } = useParams();

    return (
        <div>
            <ShowSummary showId={showId} highlights="true"/>
        </div>
    )
}
