export const jsonInit = {
    headers: {"Content-Type": "application/json"}
};

export function prettyNumber(n) {
    if (n < 1000) {
        return "" + n;
    }
    let order = Math.floor(Math.log10(n));
    switch (order) {
        case 3:
            return (n / 1_000).toFixed(1) + "K";
        case 4:
        case 5:
            return (n / 1_000).toFixed(0) + "K";
        case 6:
            return (n / 1_000_000).toFixed(1) + "M";
        default:
            return (n / 1_000_000).toFixed(0) + "M";
    }
}

export function prettyYears(json) {
    switch (json.type) {
        case "tvEpisode":
            return "(episode)";
        case "tvSeries":
        case "tvMiniSeries":
            return `(${json.year0} - ${json.year1 || "..."})`;
        default:
            return `(${json.year0})`;
    }
}

export type SessionStorage<T> = [
    () => T,
    (value: T) => void,
    () => void,
];

export function useSessionStorage<T>(key: string): SessionStorage<T> {
    const getter = (): T | undefined => {
        return sessionStorage.getItem(key);
    };

    const setter = (value: T): void => {
        sessionStorage.setItem(key, value);
    };

    const remover = (): void => {
        sessionStorage.removeItem(key);
    };

    return [
        getter,
        setter,
        remover,
    ];
}
