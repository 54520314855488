import "../styles/Layout.css"
import {Link, Outlet} from "react-router-dom";
import {SearchBar} from "../components/SearchBar";

export function Layout() {
    return (
        <>
            <nav>
                <ul>
                    <li key="home"><Link to="/">Helpful Review Score</Link></li>
                    <li key="about"><Link to="/about">About</Link></li>
                    <li key="search" className="right"><SearchBar/></li>
                </ul>
            </nav>
            <Outlet/>
        </>
    )
}
